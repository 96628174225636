import { render, staticRenderFns } from "./HomeDepositMethods.vue?vue&type=template&id=066dbff7&scoped=true"
import script from "./HomeDepositMethods.vue?vue&type=script&lang=js"
export * from "./HomeDepositMethods.vue?vue&type=script&lang=js"
import style0 from "./HomeDepositMethods.vue?vue&type=style&index=0&id=066dbff7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066dbff7",
  null
  
)

export default component.exports