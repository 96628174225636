import { render, staticRenderFns } from "./NavbarCashier.vue?vue&type=template&id=60cc3524&scoped=true"
import script from "./NavbarCashier.vue?vue&type=script&lang=js"
export * from "./NavbarCashier.vue?vue&type=script&lang=js"
import style0 from "./NavbarCashier.vue?vue&type=style&index=0&id=60cc3524&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60cc3524",
  null
  
)

export default component.exports